<template>
  <sub-page
      v-model="$store.state.settings.pageData"
      :title="$t('Workload')"
      :subtitle="$t('Structure')"
      icon="mdi-school-outline"
      fill-height
  >
    <h1>Workload will be here</h1>

  </sub-page>
</template>

<script>
export default {
  name: "workload"
}
</script>

<style scoped>

</style>